import * as React from 'react';
import styled from 'styled-components';

import Box from '../components/Box';
import ContentWrapper from '../components/ContentWrapper';
import Title from '../components/Title';

import '@fontsource/shadows-into-light';
import '@fontsource/sacramento';
import 'fontsource-open-sans/300.css';
import 'normalize.css';

// styles
const Wrapper = styled(Box).attrs({
  my: '20px',
})``;

const SubTitle = styled(Box).attrs({
  mt: '40px',
  mb: '25px',
  fontSize: ['20px', null, null, '22px'],
})`
  font-family: 'Open Sans';
  font-weight: bold;
`;
const Content = styled(Box).attrs({
  mb: '30px',
  fontSize: ['20px', null, null, '22px'],
})`
  font-family: 'Open Sans';
`;

const Anchor = styled.a`
  color: #6d7770;
  word-wrap: break-word;
  :hover {
    color: black;
  }
`;

const WhereToStay = () => (
  <Wrapper id={'where-to-stay'}>
    <ContentWrapper>
      <Title text={'Where To Stay'} />
      <Content>
        The mountains of Beaver Creek has multiple lodging options but there are a few important difference to note.  We
        want you to choose what  is best for you and look forward to a fantastic weekend with you in Colorado.
      </Content>
      <Content>
        As this is Spring Break Weekend, it is important to note that ALL HOTELS WILL SELL OUT.  It is extremely
        important you <b>RESERVE YOUR ROOM EARLY</b>. We ideally suggest booking <b>ASAP</b> for the best rate.
      </Content>
      <Content>
        No rooms are being held at any property to allow you the most flexibility in choosing the lodging option that is
        best for you.
      </Content>
      <SubTitle>Lodging in Bachelor Gulch</SubTitle>
      <Content>
        The Host hotel is the Ritz Carlton and the center of all the wedding activity. The Ceremony and Reception are
        here, and if you are an avid skier, they have their own chairlift right out the door.  They have a world class
        spa and the sleigh ride for Friday night's dinner will depart from here to Zach's cabin. The Ritz Carlton is in
        its own area- Bachelor Gulch.  This IS part of Beaver Creek proper, which means they have a free shuttle service
        that will take you all around Beaver Creek Resort via the Village Connect App. For more information, please
        visit{' '}
        <Anchor
          href={'https://www.ritzcarlton.com/en/hotels/colorado/bachelor-gulch'}
          target="_blank"
          rel="noopener noreferrer"
        >
          The Ritz Carlton Bachelor Gulch
        </Anchor>
      </Content>

      <Content>Condominiums options walking distance to the Ritz are:</Content>
      <Content>
        <ul>
          <li>
            Snowcloud -{' '}
            <Anchor
              href={'https://beavercreekmountainlodging.com/properties/snowcloud/'}
              target="_blank"
              rel="noopener noreferrer"
            >
              https://beavercreekmountainlodging.com/properties/snowcloud
            </Anchor>
          </li>
          <li>
            The Timbers -{' '}
            <Anchor href={'https://www.timbersbachelorgulch.com/'} target="_blank" rel="noopener noreferrer">
              https://www.timbersbachelorgulch.com/
            </Anchor>
          </li>
        </ul>
      </Content>
      <SubTitle>Lodging in Beaver Creek Village</SubTitle>
      <Content>Lodging options in the Main Beaver Creek Village, a short 7 minute free shuttle ride away, are</Content>
      <Content>
        Vail Resorts is offering 20% off the rate at the time of booking at St James Place, The Osprey, the Pines and
        other beaver creek properties. To ensure this discount , please call <b>1-855-332-1601</b> select #1 to reach
        the Colorado resorts, and then option #4 for Vail or Beaver Creek lodging options and use code <b>SBCKLW22</b>.
        For hotels and condos included in this discount search{' '}
        <Anchor
          href={'https://www.beavercreek.com/plan-your-trip/stay/beaver-creek-lodging.aspx'}
          target="_blank"
          rel="noopener noreferrer"
        >
          https://www.beavercreek.com/plan-your-trip/stay/beaver-creek-lodging.aspx
        </Anchor>
      </Content>
      <Content>
        The only way to receive the discount is to call the number above, the code does not apply online.
      </Content>
      <Content>
        <ul>
          <li>
            St James Place, a Condominium Property -{' '}
            <Anchor href={'https://www.stjamesplace.net/'} target="_blank" rel="noopener noreferrer">
              https://www.stjamesplace.net/
            </Anchor>
          </li>
          <li>
            The Osprey, a Condominium Property -{' '}
            <Anchor
              href={
                'https://www.beavercreek.com/Plan-Your-Trip/stay/details/The-Osprey-at-Beaver-Creek--A-RockResort?location=50016560'
              }
              target="_blank"
              rel="noopener noreferrer"
            >
              https://www.beavercreek.com/Plan-Your-Trip/stay/details/The-Osprey-at-Beaver-Creek--A-RockResort?location=50016560
            </Anchor>
          </li>
          <li>
            The Pines -{' '}
            <Anchor
              href={
                'https://www.beavercreek.com/Plan-Your-Trip/stay/details/The-Pines-Lodge-a-Rock-Resort?location=50016563'
              }
              target="_blank"
              rel="noopener noreferrer"
            >
              https://www.beavercreek.com/Plan-Your-Trip/stay/details/The-Pines-Lodge-a-Rock-Resort?location=50016563
            </Anchor>
          </li>

          <li>
            The Beaver Creek Lodge, A traditional hotel -{' '}
            <Anchor
              href={'https://www.kesslercollection.com/beaver-creek-lodge/'}
              target="_blank"
              rel="noopener noreferrer"
            >
              https://www.kesslercollection.com/beaver-creek-lodge/
            </Anchor>
          </li>
        </ul>
      </Content>
      <Content>
        All of the above properties thus far listed are serviced by the free shuttle service via Village Connect.
      </Content>
      <SubTitle>Lodging Options in Avon</SubTitle>
      <Content>
        About 3 minutes from Beaver Creek, in Avon, is{' '}
        <Anchor
          href={'https://www.marriott.com/hotels/travel/whrbw-the-westin-riverfront-resort-and-spa-avon-vail-valley/'}
          target="_blank"
          rel="noopener noreferrer"
        >
          The Westin
        </Anchor>
        . This high end hotel has a chairlift that will take you in ski gear from Avon to the Beaver Creek Mountain. 
        HOWEVER, this hotel is NOT serviced by the free shuttle system of Village Connect, so a rental car, taxi's or
        uber are needed.  We will have shuttle service to and from there on the wedding day.
      </Content>
      <SubTitle>Rental Homes/Condos</SubTitle>
      <Content>
        Airbnb and VRBO are great options but remember you want to rent a house or condo in Beaver Creek or Bachelor
        Gulch for shuttle usage.  If renting elsewhere, we recommend renting no further than Avon.  ( We do NOT
        recommend renting in Vail, Edwards, or Arrowhead)
      </Content>
      <Content>Please contact us with any questions and we look forward to celebrating with you in Colorado!</Content>
      <Content>
        <i>
          And don't forget to <b>RESERVE EARLY</b>!
        </i>
      </Content>
    </ContentWrapper>
  </Wrapper>
);

export default WhereToStay;
