import * as React from 'react';
import styled from 'styled-components';

import Skier from '../svgs/skier';
import Spa from '../svgs/spa';
import Food from '../svgs/food';
import Mountains from '../svgs/mountains';
import Shopping from '../svgs/shopping';
import Snowmobile from '../svgs/snowmobile';
import Box from '../components/Box';
import ContentWrapper from '../components/ContentWrapper';
import Title from '../components/Title';
import LinkButton from '../components/LinkButton';

import '@fontsource/shadows-into-light';
import '@fontsource/sacramento';
import 'fontsource-open-sans/300.css';
import 'normalize.css';

// styles
const Wrapper = styled(Box).attrs({
  backgroundColor: '#d0d0d0',
  py: '40px',
})``;

const Content = styled(Box).attrs({
  mb: '20px',
})`
  font-family: 'Open Sans';
  font-size: 20px;
`;

const IconGrid = styled(Box).attrs({
  display: 'grid',
  gridTemplateColumns: ['1fr', null, '1fr 1fr', null, '1fr 1fr 1fr 1fr'],
  gridColumnGap: ['50px'],
})``;

const IconWrapper = styled(Box).attrs({})`
  margin: 0 auto;
  text-align: center;
  svg {
    height: 100px;
    width: 100px;
  }
`;

const IconTitle = styled(Box)`
  font-family: 'Shadows Into Light', cursive;
  font-size: 30px;
  margin: 5px 0;
  text-align: center;
`;

const ThingsToDo = () => (
  <Wrapper id="things-to-do">
    <ContentWrapper>
      <Title text={'Things To Do'} />
      <IconGrid>
        <Box>
          <IconWrapper>
            <Skier />
          </IconWrapper>
          <IconTitle>Skiing</IconTitle>
          <Box margin={'40px auto'} textAlign={'center'}>
            <LinkButton primary to="/skiing">
              Learn More
            </LinkButton>
          </Box>
        </Box>
        <Box>
          <IconWrapper>
            <Food />
          </IconWrapper>
          <IconTitle>Dining</IconTitle>
          <Box margin={'40px auto'} textAlign={'center'}>
            <LinkButton primary to="/dining">
              Learn More
            </LinkButton>
          </Box>
        </Box>
        <Box>
          <IconWrapper>
            <Spa />
          </IconWrapper>
          <IconTitle>Pampering</IconTitle>
          <Box margin={'40px auto'} textAlign={'center'}>
            <LinkButton primary to="/relax">
              Learn More
            </LinkButton>
          </Box>
        </Box>
        <Box>
          <IconWrapper>
            <Mountains />
          </IconWrapper>
          <IconTitle>Other Activities</IconTitle>
          <Box margin={'40px auto'} textAlign={'center'}>
            <LinkButton primary to="/other-activities">
              Learn More
            </LinkButton>
          </Box>
        </Box>
      </IconGrid>
    </ContentWrapper>
  </Wrapper>
);

export default ThingsToDo;
