import * as React from 'react';
import styled from 'styled-components';

import Box from '../components/Box';
import ContentWrapper from '../components/ContentWrapper';

import '@fontsource/shadows-into-light';
import '@fontsource/sacramento';
import 'fontsource-open-sans/300.css';
import 'normalize.css';

// styles
const Content = styled(Box).attrs({
  mb: '20px',
})`
  font-family: 'Open Sans';
  font-size: 20px;
`;

const Signature = styled(Box).attrs({})`
  font-family: 'Sacramento', cursive;
  font-size: 40px;
`;

const Letter = () => (
  <ContentWrapper>
    <Content>Dear Family and Friends,</Content>
    <Content>
      March 2022. The Colorado Rockies. The mountains are snowy, the air is crisp and the ski season is upon us!
    </Content>
    <Content>
      This is the setting in which we want to commit our lives to one another, and we are <b>thrilled</b> that you are
      going to come celebrate it with us!
    </Content>
    <Content>
      Our wedding weekend will be filled with laughter and adventure. We’re doing everything we can to make sure that
      this is not just a wedding, but a wonderful vacation for you! On this website you’ll find all the details you need
      about where you’re going, how to get there, and what’s on the itinerary. We’ve also included resources that will
      help you discover the mountains of Colorado on your own!
    </Content>
    <Content>Thank you for your love and support.</Content>
    <Signature>Erica & Alex </Signature>
  </ContentWrapper>
);

export default Letter;
