import * as React from 'react';
import styled from 'styled-components';

import Box from '../components/Box';
import Button from '../components/Button';
import ContentWrapper from '../components/ContentWrapper';
import Title from '../components/Title';
import '@fontsource/shadows-into-light';
import '@fontsource/sacramento';
import 'fontsource-open-sans/300.css';
import 'normalize.css';

// styles
const Content = styled(Box).attrs({
  mb: '30px',
  fontSize: '30px',
})`
  font-family: 'Open Sans';
  text-align: center;
`;

const RegistryLocationName = styled(Box).attrs({
  mb: ['25px', null, '40px'],
})`
  font-family: 'Shadows Into Light', cursive;
  font-size: 40px;
`;

const Grid = styled(Box).attrs({
  display: 'grid',
  gridTemplateColumns: ['1fr', null, '1fr 1fr 1fr'],
  gridTemplateRows: ['repeat(3, 150px)', null, '200px'],
  textAlign: 'center',
  m: '0 auto',
})`
  align-items: center;
`;

const Registry = () => {
  return (
    <Box id="registry" my={['100px', null, '150px']}>
      <ContentWrapper>
        <Title text={'Our Registry'} />
        <Content>
          We are lucky enough to already have nearly everything we need for this new chapter together! However, for
          friends and family who have expressed an interest, we have created a gift registry for your convenience here:
        </Content>
        <Grid>
          <Box>
            <RegistryLocationName>Bloomingdales</RegistryLocationName>
            <Button
              href="https://www.bloomingdales.com/registry/wedding/guest/ERICA-KANTOR-ALEX-LAKNER/?registryId=7246848"
              target="_blank"
              rel="noreferrer noopener"
              primary
            >
              View
            </Button>
          </Box>
          <Box>
            <RegistryLocationName>Crate & Barrel</RegistryLocationName>
            <Button
              href="https://www.crateandbarrel.com/gift-registry/erica-kantor-and-alex-lakner/r6240978"
              target="_blank"
              rel="noreferrer noopener"
              primary
            >
              View
            </Button>
          </Box>
          <Box>
            <RegistryLocationName>Williams Sonoma</RegistryLocationName>
            <Button
              href="https://www.williams-sonoma.com/registry/92h2brgsff/registry-list.html"
              target="_blank"
              rel="noreferrer noopener"
              primary
            >
              View
            </Button>
          </Box>
        </Grid>
      </ContentWrapper>
    </Box>
  );
};

export default Registry;
