import * as React from 'react';
import styled from 'styled-components';
import {useStaticQuery, graphql} from 'gatsby';
import {GatsbyImage} from 'gatsby-plugin-image';

import Box from '../components/Box';
import Title from '../components/Title';
import ContentWrapper from '../components/ContentWrapper';
import LinkButton from '../components/LinkButton';
import Bus from '../svgs/bus';
import Weather from '../svgs/weather';
import Luggage from '../svgs/luggage';

import '@fontsource/shadows-into-light';
import '@fontsource/sacramento';
import 'fontsource-open-sans/300.css';
import 'normalize.css';

// styles
const Wrapper = styled(Box).attrs({
  mb: '50px',
})``;

const Header = styled(Box).attrs({})``;

const SubTitle = styled(Box).attrs({
  mt: '40px',
  mb: '25px',
  fontSize: ['22px', null, null, '25px'],
})`
  font-family: 'Open Sans';
  font-weight: bold;
`;

const Date = styled(Box).attrs({
  mt: '40px',
  mb: '10px',
  fontSize: ['20px', null, null, '22px'],
})`
  font-family: 'Open Sans';
  font-weight: bold;
`;

const Content = styled(Box).attrs({
  mb: '30px',
  fontSize: ['20px', null, null, '22px'],
})`
  font-family: 'Open Sans';
`;

const IconGrid = styled(Box).attrs({
  display: 'grid',
  gridTemplateColumns: ['1fr', null, null, '1fr 1fr 1fr'],
  gridColumnGap: ['50px'],
})``;

const IconWrapper = styled(Box).attrs({})`
  margin: 0 auto;
  text-align: center;
  svg {
    height: 100px;
    width: 100px;
  }
`;

const IconTitle = styled(Box)`
  font-family: 'Shadows Into Light', cursive;
  font-size: 30px;
  margin: 5px 0;
  text-align: center;
`;

const Details = () => {
  const {images} = useStaticQuery(graphql`
    query DetailsImages {
      images: allS3Object(filter: {Key: {glob: "content/details/*"}}) {
        nodes {
          Key
          localFile {
            childImageSharp {
              gatsbyImageData(width: 200, placeholder: BLURRED, layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  `);
  const imagesPresent = images.nodes && !!images.nodes.length;
  return (
    <Wrapper id="details">
      <Header>
        {imagesPresent &&
          images.nodes.map(photo => {
            const image = photo.localFile.childImageSharp.gatsbyImageData;
            return <GatsbyImage key={photo.Key} image={image} alt="Alex & Erica" />;
          })}
      </Header>
      <ContentWrapper>
        <Title text={'The Details'} />
        <Content>
          Please visit this page for updates- you will see more details (and perhaps slight revisions) as we get closer
          to March
        </Content>
        <Date>Thursday, March 24th</Date>
        <Content>
          SaddleRidge Dinner
          <br />
          For those who are in town, please join us for a beautiful dinner at SaddleRidge in Beaver Creek.
          <br />
          Timing and transportation details to come
        </Content>

        <Date>Friday, March 25th</Date>
        <Content>
          Rehearsal Dinner
          <br />
          Please join us for an elegant dinner at Zach’s Cabin, tucked away into the mountain above the Ritz Carlton.
          <br />
          Timing and transportation details to come
        </Content>

        <Date>Saturday, March 26th</Date>
        <Content>
          The Wedding
          <br />
          Please join us for a very special, outdoor sunset ceremony at the Ritz Carlton Bachelor Gulch followed by
          indoor reception.
          <br />
          Details to come
        </Content>

        <SubTitle>Logistics</SubTitle>
        <IconGrid>
          <Box>
            <IconWrapper>
              <Bus />
            </IconWrapper>
            <IconTitle>Getting Around</IconTitle>
            <Box margin={'40px auto'} textAlign={'center'}>
              <LinkButton primary to="/getting-around">
                Learn More
              </LinkButton>
            </Box>
          </Box>
          <Box>
            <IconWrapper>
              <Luggage />
            </IconWrapper>
            <IconTitle>Packing</IconTitle>
            <Box margin={'40px auto'} textAlign={'center'}>
              <LinkButton primary to="/packing">
                Learn More
              </LinkButton>
            </Box>
          </Box>
          <Box>
            <IconWrapper>
              <Weather />
            </IconWrapper>
            <IconTitle>Weather</IconTitle>
            <Box margin={'40px auto'} textAlign={'center'}>
              <LinkButton primary to="/weather">
                Learn More
              </LinkButton>
            </Box>
          </Box>
        </IconGrid>
      </ContentWrapper>
    </Wrapper>
  );
};

export default Details;
