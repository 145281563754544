import * as React from 'react';
import styled from 'styled-components';
import {Link} from 'gatsby';

import 'fontsource-open-sans/300.css';

const LinkButton = styled(Link)`
  background: ${props => (props.primary ? '#2B3147' : 'white')};
  color: ${props => (props.primary ? 'white' : '#2B3147')};

  font-family: 'Open Sans';
  font-size: 20px;
  text-decoration: none;
  margin: 10px;
  padding: 10px 30px;
  border: 2px solid #2b3147;
  border-radius: 15px;
  cursor: pointer;
  &:hover {
    color: ${props => (props.primary ? '#2B3147' : 'white')};
    background: ${props => (props.primary ? 'white' : '#2B3147')};
  }
`;

export default LinkButton;
