import * as React from 'react';
import styled from 'styled-components';
import {useStaticQuery, graphql} from 'gatsby';
import {GatsbyImage} from 'gatsby-plugin-image';

import Box from '../components/Box';
import Title from '../components/Title';
import ContentWrapper from '../components/ContentWrapper';

import '@fontsource/shadows-into-light';
import '@fontsource/sacramento';
import 'fontsource-open-sans/300.css';
import 'fontsource-open-sans/600.css';
import 'normalize.css';

// styles
const Wrapper = styled(Box).attrs({
  backgroundColor: '#d0d0d0',
  py: ['20px', null, null, '30px'],
})``;

const Header = styled(Box).attrs({
  display: 'grid',
  gridTemplateColumns: ['30% 35% 30%', null, null, '30% 38% 30%'],
  gridColumnGap: '10px',
  m: ['25px 0 0', null, null, '70px 100px 0'],
})``;

const SubTitle = styled(Box).attrs({
  mb: '15px',
  fontSize: ['20px', null, null, '22px'],
})`
  font-family: 'Open Sans';
  font-weight: bold;
`;
const Content = styled(Box).attrs({
  mb: '30px',
  mx: ['10px', null, null, '30px'],
  fontSize: ['20px', null, null, '22px'],
})`
  font-family: 'Open Sans';
`;

const OurStory = () => {
  const {images} = useStaticQuery(graphql`
    query StoryImages {
      images: allS3Object(filter: {Key: {glob: "content/story/*"}}) {
        nodes {
          Key
          localFile {
            childImageSharp {
              gatsbyImageData(height: 450, placeholder: BLURRED)
            }
          }
        }
      }
    }
  `);
  const imagesPresent = images.nodes && !!images.nodes.length;
  return (
    <Wrapper id="our-story">
      <Header>
        {imagesPresent &&
          images.nodes.map(photo => {
            const image = photo.localFile.childImageSharp.gatsbyImageData;
            return <GatsbyImage key={photo.Key} image={image} alt="Alex & Erica" />;
          })}
      </Header>
      <ContentWrapper>
        <Title text={'Our Story'} />
        <SubTitle>How We Met</SubTitle>
        <Content>
          It all started with a click – well more of a swipe. Erica and Alex were both on vacation at one of their
          favorite places Beaver Creek, Colorado. The trip was almost over and Erica was looking to find someone who
          could keep up with her on the mountain while Alex was looking for someone to grab a drink with. Both resorted
          to the prestigious– Jswipe dating app. Against all odds both swiped right on each other. It was their first
          step towards a future together. The banter was instant, highlighted by the endless debate as to who is a
          better skier. Alex was very confident but Erica was hesitant to believe that someone could ski as well as her.
          The only solution – a second date on the mountain. Alex said, “If you don’t believe me, meet me on the hill
          tomorrow.” Erica staying true to herself, said "let's meet at the Grouse mountain lift." Grouse being some of
          the hardest skiing on the mountain. Alex obliged. Alex met Erica at the lift and they proceeded to go up and
          as the story has it, Alex showed her that he wasn’t just as good as her… but better, at least in the trees and
          expert terrain. They skied together for a few hours and Alex was impressed -she was one of the best skiers he
          had ever met, cruising just as fast if not faster than him…this would be the first of many adventures Alex and
          Erica would go on together.
        </Content>
        <SubTitle>Us as a Couple</SubTitle>
        <Content>
          After Colorado, Alex and Erica spent multiple weekends traveling back and forth between Philly and NYC
          exploring the fun things each city offered and getting to know each other better. They realized both shared a
          love of traveling – Alex even agreed to go with Erica to her favorite place on earth -Disney World. Since then
          Erica and Alex have gone beyond Epcot and have taken trips around the world to places including Iceland,
          Barcelona, the Hamptons and the beaches of Delaware to name a few.
        </Content>
        <SubTitle>How He Asked</SubTitle>
        <Content>
          Their love grew and on February 27th, 2021, almost 2 years to date since they first met, Alex proposed to
          Erica. Alex knew the most romantic spot was right outside their apartment at Rittenhouse Square in
          Philadelphia. He had it all planned out and not even a call for Emergency Surgery at 3:30am could get in his
          way. Despite getting little sleep, he woke Erica up and suggested they go for Brunch. Alex casually brought
          her to the most romantic spot in the park. It was there that Alex told Erica how much he loved her and how
          much he wanted to spend every day with her. He got down on one knee and she said YES!!
        </Content>
        <Content>
          Now, Erica and Alex can’t wait to tie the knot and celebrate with everyone in the place they first met…. and
          finally settle who is the better skier.
        </Content>
      </ContentWrapper>
    </Wrapper>
  );
};

export default OurStory;
