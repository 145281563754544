import * as React from 'react';
import styled from 'styled-components';
import {useStaticQuery, graphql} from 'gatsby';
import BackgroundSlider from 'gatsby-image-background-slider';
import {AnchorLink} from 'gatsby-plugin-anchor-links';
import {Helmet} from 'react-helmet';
import {ThemeProvider} from 'styled-components';

import {defaultTheme} from '../styleguide/DefaultTheme';

import '@fontsource/shadows-into-light';
import '@fontsource/sacramento';
import 'fontsource-open-sans/300.css';
import 'normalize.css';

import NavBar from '../components/NavBar';
import Letter from '../components/Letter';
import OurStory from '../components/OurStory';
import Details from '../components/Details';
import GettingThere from '../components/GettingThere';
import WhereToStay from '../components/WhereToStay';
import ThingsToDo from '../components/ThingsToDo';
import Faq from '../components/Faq';
import Registry from '../components/Registry';
import Box from '../components/Box';
import Heart from '../components/Heart';

import {currentYear} from '../utils';

// styles
const Text = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 50px;
  padding-top: 135px;
  overflow: hidden;
  height: 65vh;
  text-align: center;
`;

const Header = styled(Box)`
  height: 100vh;
  overflow: hidden;
  position: absolute;
  width: 100%;
  z-index: -1;
  top: 0;
  &:before {
    background: 'rgba(0, 0, 0, 0.2)';
  }
`;

const StyledAnchorLink = styled(AnchorLink)`
  width: 100%;
  text-align: center;
  position: absolute;
  bottom: 0;
  font-family: 'Sacramento', cursive;
  font-size: 30px;
  cursor: pointer;
  color: black;
  text-decoration: none;
  padding: 40px;
  margin: 0 auto;
  :hover {
    color: #d0d0d0;
  }
`;

const Date = styled.h2`
  font-family: 'Shadows Into Light', cursive;
  font-size: 40px;
  margin: 5px 0;
`;

const Location = styled.h2`
  font-family: 'Shadows Into Light', cursive;
  font-size: 30px;
  margin: 5px 0;
`;

const Copywrite = styled.p`
  grid-area: footer;
  font-family: 'Open Sans';
  font-size: 10px;
  text-align: right;
  margin: 10px 0 0;
  padding: 20px;
  color: #d0d0d0;
  background-color: #6d7770;
`;

const ContentCard = styled(Box).attrs({
  p: ['30px', '50px', '100px', '100px'],
  mt: '90vh',
})``;

const CardHeader = styled(Box).attrs({
  fontSize: ['30px', null, '50px'],
})`
  font-family: 'Shadows Into Light', cursive;
  text-align: center;
`;

const IndexPage = () => {
  const [backgroundVisible, setBackgroundVisible] = React.useState(false);

  React.useEffect(() => {
    if (typeof window !== 'undefined') {
      const handleScroll = () => {
        const currentScrollY = window.scrollY;
        currentScrollY >= 60 ? setBackgroundVisible(true) : setBackgroundVisible(false);
      };

      window.addEventListener('scroll', handleScroll, {passive: true});

      return () => window.removeEventListener('scroll', handleScroll);
    }
  }, [backgroundVisible]);

  return (
    <ThemeProvider theme={defaultTheme}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Erica & Alex</title>
        <link rel="canonical" href="https://thelakners.com" />
      </Helmet>
      <NavBar hideNavBackground={!backgroundVisible} />
      <Header>
        <BackgroundSlider
          query={useStaticQuery(graphql`
            query {
              backgrounds: allFile(filter: {sourceInstanceName: {eq: "images"}}) {
                nodes {
                  relativePath
                  childImageSharp {
                    fluid(maxWidth: 2500, quality: 50) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          `)}
          images={['mountain.png', 'up-in-air.png', 'mountain-2.png']}
          style={{
            overflow: 'hidden',
          }}
        />
      </Header>
      <StyledAnchorLink to="/#our-story">Scroll Down</StyledAnchorLink>
      <ContentCard>
        <CardHeader>Grab your skis & Celebrate with us in Beaver Creek, Colorado!</CardHeader>
        <Letter />
      </ContentCard>
      <OurStory />
      <Details />
      <GettingThere />
      <WhereToStay />
      <ThingsToDo />
      <Registry />
      <Faq />
      <Copywrite>© Copyright {currentYear} -- Designed & Built by Erica Kantor</Copywrite>
    </ThemeProvider>
  );
};

export default IndexPage;
