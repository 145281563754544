import * as React from 'react';
import styled from 'styled-components';
import {useStaticQuery, graphql} from 'gatsby';
import {GatsbyImage, getImage} from 'gatsby-plugin-image';

import Box from '../components/Box';
import ContentWrapper from '../components/ContentWrapper';
import Title from '../components/Title';
import LinkButton from '../components/LinkButton';

import 'fontsource-open-sans/300.css';
import 'fontsource-open-sans/600.css';
import 'normalize.css';

// styles
const Wrapper = styled(Box).attrs({
  backgroundColor: '#E2DACE',
  display: 'grid',
  gridTemplateColumns: ['1fr', null, null, '40% 1fr'],
  gridGap: ['0', null, null, '40px'],
  maxWidth: '100%',
})``;

const ImageWrapper = styled(Box).attrs({
  display: ['none', null, null, 'block'],
})``;
const MobileImageWrapper = styled(Box).attrs({
  display: ['block', null, null, 'none'],
  m: '0 auto',
  pt: '10px',
})``;

const SubTitle = styled(Box).attrs({
  mb: '15px',
  fontSize: ['20px', null, null, '22px'],
})`
  font-family: 'Open Sans';
  font-weight: bold;
`;
const Content = styled(Box).attrs({
  mb: '30px',
  fontSize: ['20px', null, null, '22px'],
})`
  font-family: 'Open Sans';
`;

const LinkGrid = styled(Box).attrs({
  display: 'grid',
  gridTemplateColumns: ['1fr', null, null, '1fr 1fr'],
  mb: '30px',
  fontSize: ['20px', null, null, '22px'],
})`
  font-family: 'Open Sans';
`;

const AnchorTitle = styled.a`
  color: #6d7770;
  font-weight: bold;
  word-wrap: break-word;
  :hover {
    color: black;
  }
`;

const Anchor = styled.a`
  color: #6d7770;
  margin-top: 5px;
  word-wrap: break-word;
  :hover {
    color: black;
  }
`;

const GettingThere = () => {
  const {images} = useStaticQuery(graphql`
    query FaqImages {
      images: allS3Object(filter: {Key: {glob: "content/faq/*"}}) {
        nodes {
          Key
          localFile {
            childImageSharp {
              desktop: gatsbyImageData(width: 200, placeholder: BLURRED, layout: FULL_WIDTH)
              mobile: gatsbyImageData(width: 170, placeholder: BLURRED, layout: CONSTRAINED)
            }
          }
        }
      }
    }
  `);
  const imagesPresent = images.nodes && !!images.nodes.length;
  return (
    <Wrapper id="getting-there">
      <ImageWrapper>
        {imagesPresent &&
          images.nodes.map(photo => {
            const image = photo.localFile.childImageSharp.desktop;
            return <GatsbyImage key={photo.Key} image={image} alt="Alex & Erica" />;
          })}
      </ImageWrapper>
      <MobileImageWrapper>
        {imagesPresent &&
          images.nodes.map(photo => {
            const image = photo.localFile.childImageSharp.mobile;
            return <GatsbyImage key={photo.Key} image={image} alt="Alex & Erica" />;
          })}
      </MobileImageWrapper>
      <ContentWrapper>
        <Title text={'Getting There'} />
        <SubTitle>Arriving by Air</SubTitle>
        <Content>
          You can fly into Denver International or Eagle Vail Airport. We’ve included information about both below.
        </Content>
        <Content>
          <AnchorTitle href={'https://www.flydenver.com'} target="_blank" rel="noopener noreferrer">
            Denver International Airport
          </AnchorTitle>
        </Content>
        <Content>
          Beaver Creek is located approximately 120 miles west from Denver and about 2 hours from the Denver
          International Airport. All major airlines service the Denver airport daily, including the following:
        </Content>
        <LinkGrid>
          <Box>
            <Anchor href={'https://www.united.com'} target="_blank" rel="noopener noreferrer">
              United Airlines
            </Anchor>
          </Box>
          <Box>
            <Anchor href={'https://www.aa.com'} target="_blank" rel="noopener noreferrer">
              American Airlines
            </Anchor>
          </Box>
          <Box>
            <Anchor href={'https://www.delta.com'} target="_blank" rel="noopener noreferrer">
              Delta Airlines
            </Anchor>
          </Box>
          <Box>
            <Anchor href={'https://www.southwest.com'} target="_blank" rel="noopener noreferrer">
              Southwest Airlines
            </Anchor>
          </Box>
          <Box>
            <Anchor href={'https://www.flyfrontier.com/'} target="_blank" rel="noopener noreferrer">
              Frontier Airlines
            </Anchor>
          </Box>
        </LinkGrid>
        <Content>
          <AnchorTitle href={'https://www.eaglecounty.us/airport'} target="_blank" rel="noopener noreferrer">
            Eagle County Regional Airport (Eagle Vail)
          </AnchorTitle>
        </Content>
        <Content>
          Conveniently located 25 miles west of Beaver Creek (about a 30 minute drive). Direct flights are available
          daily from both JFK & EWR. To check the current direct routes into Eagle Vail click{' '}
          <Anchor
            href={'https://www.flyvail.com/flight-information/route-map'}
            target="_blank"
            rel="noopener noreferrer"
          >
            HERE
          </Anchor>
          . Several major carriers provide service to this airport on a seasonal basis including the following:
        </Content>
        <Content>
          <Anchor href={'https://www.united.com'} target="_blank" rel="noopener noreferrer">
            United Airlines
          </Anchor>
          <br />
          <Anchor href={'https://www.aa.com'} target="_blank" rel="noopener noreferrer">
            American Airlines
          </Anchor>
        </Content>
        <SubTitle>Transportation to and from Denver Airport and Eagle Airport</SubTitle>
        <Content>
          <Anchor
            href={'https://epicmountainexpress.com/group_redirect?code=GEMINI'}
            target="_blank"
            rel="noopener noreferrer"
          >
            Epic Mountain Express (EME)
          </Anchor>{' '}
          provides transportation directly to your hotel from the Denver International Airport and Eagle Vail Airport.
          EME is a very professional and reliable shuttle service and a great option for those who do not need a car
          while visiting Colorado. For shuttle reservations and information, contact 970.754.7433 or visit{' '}
          <Anchor
            href={'https://epicmountainexpress.com/group_redirect?code=GEMINI'}
            target="_blank"
            rel="noopener noreferrer"
          >
            their site.
          </Anchor>
        </Content>
        <Content>
          For discounts on Epic Mountain Express, please go to:{' '}
          <Anchor
            href={'https://epicmountainexpress.com/group_redirect?code=GEMINI'}
            target="_blank"
            rel="noopener noreferrer"
          >
            This Link
          </Anchor>
        </Content>
        <Content>
          <Anchor href={'https://hmtaxi.com/'} target="_blank" rel="noopener noreferrer">
            High Mountain Taxi
          </Anchor>{' '}
          provides service between Eagle Airport and Beaver Creek. We do NOT recommended trying to take a taxi or Uber
          from Denver!! Call 800.524.5555 or visit their{' '}
          <Anchor href={'https://hmtaxi.com/'} target="_blank" rel="noopener noreferrer">
            site
          </Anchor>
          .
        </Content>
        <SubTitle>Car Rentals</SubTitle>
        <Content>
          If you’re planning on renting a car (see information below about why you don’t need to!) please be advised
          that the drive can be challenging in the snow if you are not experienced with it. When in doubt, EME is an
          excellent option.
        </Content>
        <Content>
          Denver International Airport: All major car rental companies are represented at Denver airport.
        </Content>
        <Content>
          Eagle County: Many car rental companies are represented at Eagle County airport. Both Hertz and Avis have
          offices within the regional airport.
        </Content>
        <Box margin={'40px auto'} textAlign={'center'}>
          <LinkButton primary to="/directions">
            Driving Directions
          </LinkButton>
        </Box>
      </ContentWrapper>
    </Wrapper>
  );
};

export default GettingThere;
