import React from 'react';

const Skier = () => (
  <svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" id="Layer_1" version="1" viewBox="0 0 24 24">
    <circle cx="19" cy="5" r="2" />
    <path d="M10.3 7.5c0 0 1.8-2.5 4.5-2.5C16.2 5 17 5.9 17 7.1c0 0.2-0.1 3.7-0.1 3.7l-0.8-2.3L13.6 10 10.3 7.5z" />
    <line fill="none" stroke="#000000" strokeMiterlimit="10" strokeWidth="1.5" x1="14" x2="5.1" y1="9.4" y2="3.8" />
    <path d="M20.4 20c-1.4 0.7-3.4 0.3-4.8-0.5L3.1 12.4l-1 1.7 12.5 7.1c0.8 0.5 1.8 0.7 2.7 0.7 0.9 0 1.7-0.2 2.5-0.6l1.6-0.9L20.4 20z" />
    <path
      d="M20.1 13.1l-3-1.9C16.4 10.8 16 10 16 9.1c0-0.9 0-1.9 0-1.9"
      fill="none"
      stroke="#000000"
      strokeMiterlimit="10"
      strokeWidth="2"
    />
    <path
      d="M9.2 17.1l4.2-2.4c0.8-0.5 0.9-1.6 0.1-2.1 -1.4-1-3.3-2.2-3.3-2.2"
      fill="none"
      stroke="#000000"
      strokeMiterlimit="10"
      strokeWidth="2"
    />
    <path d="M13.8 11.7L9.2 8.5 8.8 8.9c-1 1-0.7 2.6 0.5 3.2l4.3 2.1L13.8 11.7z" />
  </svg>
);

export default Skier
