import * as React from 'react';
import styled from 'styled-components';
import {useStaticQuery, graphql} from 'gatsby';
import {GatsbyImage, getImage} from 'gatsby-plugin-image';
import {Link} from 'gatsby';
import {AnchorLink} from 'gatsby-plugin-anchor-links';

import Box from '../components/Box';
import Title from '../components/Title';
import ContentWrapper from '../components/ContentWrapper';

import '@fontsource/shadows-into-light';
import '@fontsource/sacramento';
import 'fontsource-open-sans/300.css';
import 'normalize.css';

// styles
const Wrapper = styled(Box).attrs({
  backgroundColor: '#E2DACE',
  py: '25px',
  mb: '-10px',
})``;

const StyledLink = styled(Link)`
  color: #6d7770;
  :hover {
    color: black;
  }
`;

const StyledAnchorLink = styled(AnchorLink)`
  color: #6d7770;
  :hover {
    color: black;
  }
`;

const Question = styled(Box).attrs({
  pb: '15px',
})`
  font-family: 'Open Sans';
  font-size: 22px;
  font-weight: bold;
`;

const Answer = styled(Box).attrs({
  pt: '0',
  pb: '30px',
})`
  font-family: 'Open Sans';
  font-size: 22px;
`;

const CheckBack = styled(Box)`
  font-family: 'Shadows Into Light', cursive;
  font-size: 30px;
  margin: 5px 0;
  text-align: center;
`;

// <Question>Which airport should I fly into?</Question>
// <Answer> </Answer>
const Faq = () => {
  return (
    <Wrapper id="faq">
      <ContentWrapper>
        <Title text={'FAQs'} />
        <Question>When should I plan to arrive?</Question>
        <Answer>
          This is totally up to you. The main event, the wedding, will begin on Saturday March 26th at around 5:30pm.
          Because travel in the mountains can be unpredictable we recommend people AT LEAST make it to Denver by Friday
          evening. There will be dinners leading up to the big day on Thursday and Friday night. Alex & Erica plan on
          spending the week leading up to the wedding in Beaver Creek, so if you are there early, don't worry! Come ski
          and enjoy the magic of Colorado with us!
        </Answer>
        <Question>When should I plan to depart?</Question>
        <Answer>
          If you plan on leaving from Denver, it is best to not book a flight for first thing in the morning, since the
          drive from Beaver Creek is AT LEAST 2.5 hours. We will be hosting a brunch Sunday morning at the Ritz for all
          to say goodbye to the happily married couple.
        </Answer>
        <Question>What should I pack?</Question>
        <Answer>
          We recommend checking the <StyledLink to="/weather">weather</StyledLink> before you depart since it can be
          very variable in March. For more information visit the <StyledLink to="/packing">Packing Page</StyledLink>.
        </Answer>
        <Question>Will I be able to ski while I am out there?</Question>
        <Answer>
          Absolutely! We’d love to show you all of our favorite trails at both Vail and Beaver Creek. Alex and Erica
          will be skiing all week leading up to the big day! For more details visit our{' '}
          <StyledLink to="/skiing">Skiing Page</StyledLink>.
        </Answer>
        <Question>What time is the mountain open?</Question>
        <Answer>The mountain is open from 8:30am - 4:00pm. Please note that some lifts close before 4:00pm</Answer>
        <Question>What is the dress code?</Question>
        <Answer>
          Information about the dress code can be found on the <StyledLink to="/packing">Packing Page</StyledLink>.
        </Answer>
        <Question>Where are Alex and Erica Staying?</Question>
        <Answer>
          We will be staying in Bachelor Gulch adjacent to the Ritz Carlton Hotel. We recommend staying in the Bachelor
          Gulch area if possible to be close to all the action. However, staying in Beaver Creek Village has its own
          benefits and allows you transportation through the village connect system to come to Bachelor Gulch at any
          time. Additionally prices in Avon are more reasonable, yet there is not access to the Village connect system.
          Alex and Erica are happy to help you make the decision.
        </Answer>
        <Question>Is there a room block?</Question>
        <Answer>
          No rooms are being held at any property to allow you the most flexibility in choosing the lodging option that
          is best for you. Alex and Erica can help you with this selection if you wish. Please refer to the{' '}
          <StyledAnchorLink to="/#where-to-stay">Where to Stay Section</StyledAnchorLink> for more details.
        </Answer>
        <Question>Are kids included?</Question>
        <Answer>
          The weekend’s events are adult only, although we happily encourage you to bring your family out and show them
          the magic that is Colorado in the wintertime. Links to baby-sitters and other related details to come.
        </Answer>
        <Question>I am not a skier, what is there for me to do during the day?</Question>
        <Answer>
          There are a ton of amazing activities for those who do not ski. This includes amazing shopping and art
          galleries both in Beaver Creek and Vail, snowshoeing or cross country skiing up on the mountain with
          incredible vistas, relaxing spas, wonderful restaurants, and much more. See the{' '}
          <StyledLink to="/other-activities">Other Activites Page</StyledLink> for more information.
        </Answer>
        <Question>How Far is Vail from Beaver Creek?</Question>
        <Answer>
          Approximately 20 minutes. There are transportation options to take you to Vail for an incredible day of skiing
          or to walk around this amazing ski resort town. Alex and Erica will be likely be skiing Vail mid week for
          those of you who’d like to join.
        </Answer>
        <Question>What is Bachelor Gulch?</Question>
        <Answer>
          It is an exclusive area of Beaver Creek Resort tucked away from the main Beaver Creek Resort Base with its own
          ski trails and ski lifts but is connected by ways of the mountain and access roads leading into the resort.
          Bachelor Gulch is home to the Ritz Carlton Hotel which will be the host hotel and center for the wedding
          activities.
        </Answer>
        <Question>Where is Avon in relation to Bachelor Gulch and Beaver Creek?</Question>
        <Answer>
          It is located below both Bachelor Gulch and Beaver Creek Resort in the valley and therefore not ski-in/ski
          out, however it does provide more economical options for lodging. The Westin Hotel Avon has its own Gondola
          which takes you to a chairlift that leads right to the base of Bachelor Gulch.
        </Answer>
        <Question>What about Covid?</Question>
        <Answer>
          This has been an unprecedented year for all of us. We understand that we have guests that are
          immunocompromised, may be pregnant, of advanced age, etc. We truly hope that by our wedding date, that this
          will all just be a memory. However, if there is still risk associated, we will be adhering to the latest
          guidelines provided by the CDC including mask wearing and other restrictions. Your health and happiness is our
          top priority. We will be updating this section as the date gets closer and we have a better idea of the
          current situation and guidelines.
        </Answer>
        <CheckBack>
          We will be updating this site throughout the next year with more information & details, so be sure to check
          back!
        </CheckBack>
      </ContentWrapper>
    </Wrapper>
  );
};

export default Faq;
